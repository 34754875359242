/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

          jQuery(function() {
              jQuery('.match-height').matchHeight();
          });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'project': {
      init: function() {
        // JavaScript to be fired on the about us page

          function checkCharLimit() {
              var max = 75;
              var len = jQuery('#project_title').val().length;
              if (len >= max) {
                  jQuery('.titlelimiter .limit').text(0);
                  jQuery('.titlelimiter').addClass('exceeded');
              } else {
                  var char = max - len;
                  jQuery('.titlelimiter .limit').text(char);
                  jQuery('.titlelimiter').removeClass('exceeded');
              }
          }

          jQuery('#project_title').keyup(function () {
            checkCharLimit();
          });

          jQuery(document).ready(function () {
            checkCharLimit();
          });

          function checkCaptionCharLimit(input, limiter) {
            var max = 30;
            var len = jQuery(input).val().length;
            if (len >= max) {
                jQuery(limiter + ' .limit').text(0);
                jQuery(limiter).addClass('exceeded');
            } else {
                var char = max - len;
                jQuery(limiter + ' .limit').text(char);
                jQuery(limiter).removeClass('exceeded');
            }
          }

          jQuery('#project_image_caption').keyup(function () {
            checkCaptionCharLimit('#project_image_caption', '.projectimagelimiter');
          });

          jQuery(document).ready(function () {
            checkCaptionCharLimit('#project_image_caption', '.projectimagelimiter');
          });

          function initialCheckWordLimit() {
              var wordLen = 100, len, lenObj, words;

              lenObj = jQuery('#project_content').val().split(/[\s]+/);
              len = lenObj.length;

              if (len === 1) {
                  charLen = jQuery('#project_content').val().length;
                  if (charLen === 0) {
                      len = 0;
                  }
              }

              words = wordLen - len;

              if (len >= wordLen) {
                  jQuery('.contentlimiter .limit').text(0);
                  jQuery('.contentlimiter').addClass('exceeded');
              } else {
                  jQuery('.contentlimiter .limit').text(words);
                  jQuery('.contentlimiter').removeClass('exceeded');
              }
          }

          function checkWordLimit() {
              var wordLen = 100, len, lenObj, words, charLen;

              jQuery('#project_content').on('keydown blur', function (event) {

                  lenObj = jQuery('#project_content').val().split(/[\s]+/);
                  len = lenObj.length;

                  if (len === 1) {
                      charLen = jQuery('#project_content').val().length;
                      if (charLen === 0) {
                          len = 0;
                      }
                  }

                  words = wordLen - len;


                  if (len > wordLen) {

                      if (event.keyCode === 46 || event.keyCode === 8) {

                      } else if (event.keyCode < 48 || event.keyCode > 57) {
                          event.preventDefault();
                      }

                      jQuery('.contentlimiter .limit').text(0);
                      jQuery('.contentlimiter').addClass('exceeded');

                  } else {

                      jQuery('.contentlimiter .limit').text(words);
                      jQuery('.contentlimiter').removeClass('exceeded');

                  }

              });
          }

          function initialCheckExcerptWordLimit() {
            var wordLen = 30, len, lenObj, words;

            lenObj = jQuery('#project_excerpt').val().split(/[\s]+/);
            len = lenObj.length;

            if (len === 1) {
                charLen = jQuery('#project_excerpt').val().length;
                if (charLen === 0) {
                    len = 0;
                }
            }

            words = wordLen - len;

            if (len >= wordLen) {
                jQuery('.excerptcontentlimiter .limit').text(0);
                jQuery('.excerptcontentlimiter').addClass('exceeded');
            } else {
                jQuery('.excerptcontentlimiter .limit').text(words);
                jQuery('.excerptcontentlimiter').removeClass('exceeded');
            }
        }

        function checkExcerptWordLimit() {
            var wordLen = 30, len, lenObj, words, charLen;

            jQuery('#project_excerpt').on('keydown blur', function (event) {

                lenObj = jQuery('#project_excerpt').val().split(/[\s]+/);
                len = lenObj.length;

                if (len === 1) {
                    charLen = jQuery('#project_excerpt').val().length;
                    if (charLen === 0) {
                        len = 0;
                    }
                }

                words = wordLen - len;


                if (len > wordLen) {

                    if (event.keyCode === 46 || event.keyCode === 8) {

                    } else if (event.keyCode < 48 || event.keyCode > 57) {
                        event.preventDefault();
                    }

                    jQuery('.excerptcontentlimiter .limit').text(0);
                    jQuery('.excerptcontentlimiter').addClass('exceeded');

                } else {

                    jQuery('.excerptcontentlimiter .limit').text(words);
                    jQuery('.excerptcontentlimiter').removeClass('exceeded');

                }

            });
        }

          jQuery(document).ready(function () {
              initialCheckWordLimit();
              checkWordLimit();
              initialCheckExcerptWordLimit();
              checkExcerptWordLimit();
          });

          categoryFilter = function () {

              var areaClass     = '#project_course_area',
                  courseClass   = '#project_course';

              jQuery(courseClass).on('change', function(){

                  var courseSelect = jQuery(this),
                      course = courseSelect.val(),
                      areaSelect = courseSelect.parents('form').find(areaClass);

                  if(course !== "") {

                      jQuery("#project_course_area").chosen("destroy");
                      jQuery("#project_course_area").hide();
                      jQuery("#project_course_area_label").hide();
                      jQuery("#project_course_area_chosen").remove();

                      jQuery(".course-spinner").show().html("<i class='fa fa-cog fa-spin fa-2x fa-fw'></i>");

                      jQuery.ajax({

                          type: 'POST',

                          url: theme_ajax.url,

                          data: { course : course, action: 'populate_categories_dropdown' },

                          success: function(data, textStatus, XMLHttpRequest) {

                              areaSelect.empty();
                              var options = jQuery.parseJSON(data);
                              for(i=0;i<options.length;i++){
                                  areaSelect.append('<option value="'+options[i].value+'">'+options[i].text+'</option>');
                              }

                              jQuery(".course-spinner").html("").hide();
                              jQuery("#project_course_area").show();
                              jQuery("#project_course_area_label").show();

                              jQuery("#project_course_area").chosen({search_contains:true, disable_search_threshold: 10});

                              jQuery(window).resize(function () {
                                  var width = jQuery(".input-lg")[0].offsetWidth + "px";
                                  jQuery(".chosen-container").css("width", width);
                              });

                          },

                          error: function(MLHttpRequest, textStatus, errorThrown) {
                              console.log(MLHttpRequest);
                              console.log(textStatus);
                              console.log(errorThrown);
                              alert('Error, please contact support.');

                          }

                      });


                  }

              });

          };

          jQuery(document).ready(function () {
              categoryFilter();
          });

          jQuery(".chosen-select").chosen({search_contains:true, disable_search_threshold: 3});

          jQuery(window).resize(function () {
              var width = jQuery(".input-lg")[0].offsetWidth + "px";
              jQuery(".chosen-container").css("width", width);
          });

      }
    },
    // About us page, note the change from about-us to about_us.
    'profile': {
      init: function() {
        // JavaScript to be fired on the about us page
          jQuery(".chosen-select").chosen({search_contains:true});

          jQuery(window).resize(function () {
              var width = jQuery(".ginput_container_select")[0].offsetWidth + "px";
              jQuery(".ginput_container_select .chosen-container").css("width", width);
          });
      }
    },
    // About us page, note the change from about-us to about_us.
    'projects': {
      init: function() {
        // JavaScript to be fired on the about us page


          function isEmail(email) {
              var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,20})+$/;
              return regex.test(email);
          }

          function validateFields(formid) {
              var alldone = [];

              var contactProjectID = jQuery('#contactProjectID' + formid).val();
              var contactMessage = jQuery('#contactMessage' + formid).val();
              var contactName = jQuery('#contactName' + formid).val();
              var contactEmail = jQuery('#contactEmail' + formid).val();
              var contactConfirmEmail = jQuery('#contactConfirmEmail' + formid).val();

              if ( (contactProjectID === "") || (contactProjectID === "undefined") ) {
                  alldone.push("\tSystem Error. Please reload and try again.");
              }
              if ( (contactMessage === "") || (contactMessage === "undefined") ) {
                  alldone.push("\tMessage must be filled in");
              }
              if ( (contactName === "") || (contactName === "undefined") ) {
                  alldone.push("\tName must be filled in");
              }
              if ( (contactEmail === "") || (contactEmail === "undefined") ) {
                  alldone.push("\tEmail Address field must be filled in");
              } else {
                  if (!isEmail(contactEmail)) {
                      alldone.push("\tEmail Address field must be valid");
                  }
              }
              if ( (contactConfirmEmail === "") || (contactConfirmEmail === "undefined") ) {
                  alldone.push("\tConfirm Email Address field must be filled in");
              } else {
                  if (!isEmail(contactConfirmEmail)) {
                      alldone.push("\tConfirm Email Address field must be valid");
                  }
              }

              if ( ( (contactEmail !== "") || (contactEmail !== "undefined") ) && (contactEmail !== contactConfirmEmail) ) {
                  alldone.push("\tEmail Address field must match Confirm Email Address field");
              }

              return alldone;

          }

          jQuery(".thank-you").on('click', function() {
             jQuery('.thank-you-overlay').fadeOut();
          });

          jQuery(".contact-form-submit").on('click', function(e) {

              var button = jQuery(this);
              var formid = button.data('formid');

              var precheck = validateFields(formid);

              if (precheck.length === 0) {

                  button.html('SENDING <i class="fa fa-cog fa-spin"></i>');

                  var formData = jQuery('#contactForm' + formid).serialize() + "&" + "&action=submitStudentContact";

                  jQuery.ajax({

                      type: 'POST',

                      url: theme_ajax.url,

                      data: formData,

                      success: function(data, textStatus, XMLHttpRequest) {

                          var results = JSON.parse(data);

                          if (results.status === 'success') {

                              jQuery('.thank-you-overlay').fadeIn();

                              jQuery('#contactForm' + formid).trigger("reset");
                              jQuery('#headingForm' + formid + ' a').trigger('click');

                              button.html('SEND');

                          } else {

                              alert('Your entry did not complete, please try again.');

                              button.html('SEND');
                          }

                      },

                      error: function(MLHttpRequest, textStatus, errorThrown) {
                          console.log(MLHttpRequest);
                          console.log(textStatus);
                          console.log(errorThrown);
                          alert('Error, please contact support.');
                      }

                  });

              } else {

                  var checker = validateFields(formid);

                  var message = "Please check the following fields:\n\n" + checker.join("\n");

                  alert(message);

              }

          });

          // Automatically submit project search form when user clicks 'Show projects from students who are from the UK.' checkbox.
          jQuery("#show_projects_uk").on('click', function(e) {
            jQuery(".project-search").submit();
          });
      }
    },
    // About us page, note the change from about-us to about_us.
    'register': {
      init: function() {
        // JavaScript to be fired on the about us page
          jQuery(".chosen-select").chosen({search_contains:true});

          jQuery(window).resize(function () {
              var width = jQuery(".ginput_container_select")[0].offsetWidth + "px";
              jQuery(".ginput_container_select .chosen-container").css("width", width);
          });
      }
    },
    // Login page
    'login': {
        init: function () {

            jQuery("#wp-submit").click(function () {
                var user = jQuery("input#loginemail").val();
                var pass = jQuery("input#loginpassword").val();
                if (user === "") {
                    jQuery("input#loginemail").focus();
                    return false;
                }
                if (pass === "") {
                    jQuery("input#loginpassword").focus();
                    return false;
                }
            });


        }
    },
    'password': {
        init: function () {

            // for lost password
            jQuery("form#lostPasswordForm").submit(function () {
                var submit = jQuery("div#lostPassword #wp-submit"),
                    //preloader = jQuery("div#lostPassword #preloader"),
                    message = jQuery("div#lostPassword #message"),
                    contents = {
                        action: 'lost_pass',
                        nonce: this.user_lost_password_nonce.value,
                        user_login: this.user_login.value
                    };

                // disable button onsubmit to avoid double submision
                submit.attr("disabled", "disabled").addClass('disabled');

                // Display our pre-loading
                //preloader.css({'visibility':'visible'});

                jQuery.post(theme_ajax.url, contents, function (data) {
                    submit.removeAttr("disabled").removeClass('disabled');

                    // hide pre-loader
                    //preloader.css({'visibility':'hidden'});

                    // display return data
                    message.html(data);
                });

                return false;
            });


            // for reset password
            jQuery("form#resetPasswordForm").submit(function () {
                var submit = jQuery("div#resetPassword #wp-submit"),
                    //preloader = jQuery("div#resetPassword #preloader"),
                    message = jQuery("div#resetPassword #message"),
                    contents = {
                        action: 'reset_pass',
                        nonce: this.user_reset_password_nonce.value,
                        pass1: this.pass1.value,
                        pass2: this.pass2.value,
                        user_key: this.user_key.value,
                        user_login: this.user_login.value
                    };

                // disable button onsubmit to avoid double submision
                submit.attr("disabled", "disabled").addClass('disabled');

                // Display our pre-loading
                //preloader.css({'visibility':'visible'});

                jQuery.post(theme_ajax.url, contents, function (data) {
                    submit.removeAttr("disabled").removeClass('disabled');

                    // hide pre-loader
                    //preloader.css({'visibility':'hidden'});

                    // display return data
                    message.html(data);
                });

                return false;
            });


        }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

